













































































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { User, UserHives } from '@/types';
import MyHive from '@/components/MyHive.vue';

@Component({
  components: {
    MyHive,
  },
})
export default class MyHivesDialog extends Vue {
  dialog = false
  loading = false
  valid = false

  get value(): boolean {
    return this.$store.getters.myHivesDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get userHives(): UserHives {
    return this.currentUser.hives ?? {};
  }

  get hivesCount(): number {
    return this.currentUser.hives && Object.keys(this.currentUser.hives)
      ? Object.keys(this.currentUser.hives).length : 0;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'MyHivesDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'MyHivesDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'myHives');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }
}
